import React, {Suspense } from 'react';
import { Routes, Route, Navigate} from 'react-router-dom';
import { useIsAuthenticated} from "react-auth-kit";

const Login = React.lazy(() => import('./pages/Authentification/Login'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const ShopList = React.lazy(()=> import('./pages/PagesSite/ShopList'));
const ApiDoc = React.lazy(()=> import('./pages/PagesSite/ApiDoc'));
const UserList = React.lazy(()=> import('./pages/PagesSite/UserList'));
const AddUser = React.lazy(()=> import('./pages/PagesSite/AddUser'));
const Raports = React.lazy(()=> import('./pages/PagesSite/Raports'));
const AddShop = React.lazy(()=> import('./pages/PagesSite/AddShop'));
const PrevApiDoc = React.lazy(()=> import('./pages/PagesSite/PrevApiDoc'));
const CreateApiDoc = React.lazy(()=> import('./pages/PagesSite/CreateApiDoc'));
const AddNewMobile = React.lazy(() => import('./pages/PagesSite/AddNewMobile'));
const ListPushNote = React.lazy(()=> import('./pages/PagesSite/ListPushNote'));
const ConfirmResetAccount = React.lazy(()=> import('./pages/Authentification/ConfirmResetAccount'));
const PriceList = React.lazy(()=> import('./pages/PagesSite/PriceList'));
const PrefPriceList = React.lazy(()=> import('./pages/PagesSite/PrefPriceList'));
const MonthRaports = React.lazy(()=> import('./pages/PagesSite/MonthRaports'));

function App() {

   const RequireAuth = ({ children }) => {
      const isAuthenticated = useIsAuthenticated();
 
      if (!isAuthenticated()) {
        return <Navigate to="/login" />;
      }
    
      return <>{children}</>;
   };

  return (
    <Suspense fallback={
      <div style={{width:'100%',height:'100vh',backgroundColor:'#fff',display:'flex',justifyContent:'center',alignItems:'center'}}>
         <svg version="1.1" id="loader-1" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
            <path opacity="0.2" fill="#4d61fc" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
               s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
               c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
            <path fill="#4d61fc" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
               C22.32,8.481,24.301,9.057,26.013,10.047z">
            <animateTransform attributeType="xml"
               attributeName="transform"
               type="rotate"
               from="0 20 20"
               to="360 20 20"
               dur="0.5s"
               repeatCount="indefinite"
            />
            </path>
         </svg>
      </div>
   }>
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
         <Route path="/login" element={<Login/>}/>
         <Route path="/admin-confirm-account/:uuid" element={<ConfirmResetAccount/>}/>
         <Route path="/admin-reset-password-account/:uuid" element={<ConfirmResetAccount/>}/>
         <Route path="/" element={
            <RequireAuth loginPath='/login'>
               <Dashboard/>
            </RequireAuth>
         }/>

         <Route path="/shop-list" element={
            <RequireAuth loginPath='/login'>
               <ShopList/>
            </RequireAuth>
         }/>

         <Route path="/documentation-api" element={
            <RequireAuth loginPath='/login'>
               <ApiDoc/>
            </RequireAuth>
         }/>
         <Route path="/documentation-api/:id" element={
            <RequireAuth loginPath='/login'>
               <PrevApiDoc/>
            </RequireAuth>
         }/>

         <Route path="/documentation-api-create/:id" element={
            <RequireAuth loginPath='/login'>
               <CreateApiDoc/>
            </RequireAuth>
         }/>

         <Route path="/user-list" element={
            <RequireAuth loginPath='/login'>
               <UserList/>
            </RequireAuth>
         }/>

         <Route path="/create-user" element={
            <RequireAuth loginPath='/login'>
               <AddUser/>
            </RequireAuth>
         }/>

         <Route path="/raports" element={
            <RequireAuth loginPath='/login'>
               <Raports/>
            </RequireAuth>
         }/>

         <Route path="/month-raports" element={
            <RequireAuth loginPath='/login'>
               <MonthRaports/>
            </RequireAuth>
         }/>

         <Route path="/add-shop" element={
            <RequireAuth loginPath='/login'>
               <AddShop/>
            </RequireAuth>
         }/>

         <Route path="/add-mobile-app-push" element={
            <RequireAuth loginPath='/login'>
               <AddNewMobile/>
            </RequireAuth>
         }/>

         <Route path="/list-mobile-app-push" element={
            <RequireAuth loginPath='/login'>
               <ListPushNote/>
            </RequireAuth>
         }/>

         <Route path="/price-list" element={
            <RequireAuth loginPath='/login'>
               <PriceList/>
            </RequireAuth>
         }/>

         <Route path="/price-pref-list" element={
            <RequireAuth loginPath='/login'>
               <PrefPriceList/>
            </RequireAuth>
         }/>

      </Routes>
   </Suspense>
  );
}

export default App;
